.alert-metrics {
  padding-left: 30px;
  padding-right: 30px;
}

.month-selector {
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  margin-left: 15px;
  border-radius: 10px;
}

.metrics-row {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
}

.metrics-table-container,
.metrics-chart-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metrics-table-container table {
  border: 2px solid rgb(179, 179, 179);
  border-collapse: collapse;
}

.metrics-table-container td,
.metrics-table-container th {
  border: 1px solid rgb(179, 179, 179);
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
}
